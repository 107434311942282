import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import NotFound from './NotFound';
import { checkLogin } from '../utils/auth';

// Routes
import { DefaultLayout, defaultRoute, routes } from '../routes';

import { connect } from 'react-redux';
import { getSignAPI, publicApi } from '../apis';
import { setUser } from '../reducers/actions/user';
import { setHomeInfo } from '../reducers/actions/home';
import { setUserAPI } from '../reducers/actions/userAPI';
import { apim_logs } from '../apim_logs';

class Router extends Component {
	constructor(props) {
		super(props);

		this.prevPath = '';
		// Set default layout
		this.routes = routes.map((route) => {
			route.layout = route.layout || DefaultLayout;
			route.exact = typeof route.exact === 'undefined' ? true : route.exact;
			return route;
		});

		// Set app loading class
		document.documentElement.classList.add('app-loading');
	}

	async componentDidMount() {
		const splashScreen = document.querySelector('.app-splash-screen');
		const removeLoadingClass = () => {
			document.documentElement.classList.remove('app-loading');
		};

		// home
		if (!this.props.homeInfo?.id) {
			await publicApi
				.get(`/home-info?active_slide_only=true`)
				.then(async ({ data }) => {
					await this.props.dispatchHomeInfo(data);
				})
				.catch((err) => {
					console.warn('Router [get.HomeInfo.error]', err);
				});
		}

		await this.props.dispatchUserAPI(await getSignAPI());

		// Remove splash screen
		if (splashScreen) {
			splashScreen.style.opacity = 0;
			setTimeout(() => {
				// eslint-disable-next-line no-unused-expressions
				splashScreen?.parentNode?.removeChild(splashScreen);
				removeLoadingClass();
			}, 150);
		} else {
			removeLoadingClass();
		}
	}

	async componentDidUpdate() {
		await this.onChangeRoute(window.location.pathname);
	}

	async onChangeRoute(path) {
		apim_logs(`router change : "${path}"`);
		const checkPath = !/^\/(my-apps|application|statistics|admin|user-info|forum)/.test(path);
		const user = await checkLogin(checkPath, '');
		if (this.props.user?.id !== user?.id) {
			await this.props.dispatchUser(user);
			apim_logs(`get user info`, user);
		}

		if (process.env.NODE_ENV !== 'production') window.DEV_USER_ID = user?.id;

		const settingInfo = this.props.settingInfo;
		const role = this.props.user?.role;
		if (!role || !settingInfo.filter) return;

		if (/\/forum/.test(path)) {
			if (settingInfo.filter(v => v.type  === "forum_activation" && !v.config?.active).length > 0) {
				window.location.href = `/`;
				return;
			}
		} else if (/\/ip-block/.test(path)) {
			if (settingInfo.filter(v => v.type  === "ip_whitelist" && !v.config?.active).length > 0) {
				window.location.href = `/`;
				return;
			}
		}
	}

	setTitle(title) {
		document.title = title;
	}

	scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
		if (element.scrollTop === to) return;
		const start = element.scrollTop;
		const change = to - start;
		const startDate = +new Date();

		if (!duration) {
			element.scrollTop = to;
			return;
		}

		// t = current time; b = start value; c = change in value; d = duration
		const easeInOutQuad = (t, b, c, d) => {
			t /= d / 2;
			if (t < 1) return (c / 2) * t * t + b;
			t--;
			return (-c / 2) * (t * (t - 2) - 1) + b;
		};

		const animateScroll = () => {
			const currentDate = +new Date();
			const currentTime = currentDate - startDate;
			element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration));
			if (currentTime < duration) {
				requestAnimationFrame(animateScroll);
			} else {
				element.scrollTop = to;
			}
		};

		animateScroll();
	}

	render() {
		return (
			<BrowserRouter basename={process.env.REACT_APP_BASENAME}>
				<Switch>
					{this.routes.map((route) => (
						<Route
							path={route.path}
							exact={route.exact}
							render={(props) => {
								// on change
								// apim_logs(`this.prevPath : "${this.prevPath}" -> "${props.location.pathname}"`);
								// if (this.prevPath !== props.location.pathname) {
								// 	this.onChangeRoute((this.prevPath = props.location.pathname));
								// }

								// Scroll page to top on route render
								this.scrollTop(0, 0);

								// Return layout
								return (
									<route.layout {...{ title: route.title, ...props }}>
										<route.component {...props} setTitle={this.setTitle} scrollTop={this.scrollTop} />
									</route.layout>
								);
							}}
							key={route.path}
						/>
					))}
					{<Redirect from="/" to={defaultRoute} exact={true} />}

					{/* NotFound page */}
					<Route path="*" component={NotFound} />
				</Switch>
			</BrowserRouter>
		);
	}
}

// export default Router
export default connect(
	(state) => ({
		user: state.user.data,
		homeInfo: state.home.homeInfo,
		settingInfo: state.setting.settingInfo,
	}),
	(dispatch) => ({
		dispatchUser: (amount) => dispatch(setUser(amount)),
		dispatchHomeInfo: (amount) => dispatch(setHomeInfo(amount)),
		dispatchUserAPI: (amount) => dispatch(setUserAPI(amount)),
	})
)(Router);
