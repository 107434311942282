import React, { Component } from 'react'
import styles from './Loader.module.scss'

class Loader extends Component {
  async componentWillUnmount() {
    await new Promise((resolve: any) => 
      setTimeout(() => resolve(), 5000)
    )
  }

  render() {
    return (
      <div className="loading-spinner"></div>
    )
  }
}

export default Loader
