import { signApi } from "../apis";
import { SweetAlert } from "../components";

let loginAlertShow = false;

export const checkAPIM = (role: string[] = []): boolean => {
	return role.includes("apim-admin");
};

export const checkDevAdmin = (role: string[] = []): boolean => {
	return role.includes("devportal-admin") || checkAPIM(role);
};

export const checkPjtAdmin = (role: string[] = []): boolean => {
	return role.includes("devportal-pjt-admin") || checkDevAdmin(role);
};

export const checkMember = (role: string[] = []): boolean => {
	return /devportal-/.test(role.join("|"));
};

export const checkAdmin = (role: string[] = []): boolean => {
	return role.includes("devportal-admin") || role.includes("devportal-api-admin") || checkAPIM(role);
};

export const checkPortalAdmin = (role: string[] = []): boolean => {
	return role.includes("devportal-admin") || checkAPIM(role);
};

export const emailCheck = (email: string): boolean => {
	return /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email);
};

export const getPasswordErrorMessage = (status: number): string => {
	return status === 404
		? "유저 정보를 확인할 수 없습니다."
		: status === 401
		? "잘못된 비밀번호 입니다.\n비밀번호를 확인해 주세요."
		: status === 402
		? "이전에 사용했던 비밀번호를 다시 설정할 수 없습니다."
		: "";
};

// ------------------

export const pwdCheck = (pwd: string, showAlert: boolean): boolean => {
	let msg = "";

	if (!pwd || typeof pwd !== "string") {
		msg = "유효하지 않은 비밀번호 설정";
	} else {
		const num = pwd.search(/[0-9]/g);
		const eng = pwd.search(/[a-z]/gi);
		const spe = pwd.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);
		if (pwd.length < 10 || pwd.length > 36) {
			msg = "10자리 ~ 36자리 이내로 입력해주세요.";
		} else if (pwd.search(/\s/) != -1) {
			msg = "비밀번호는 공백 없이 입력해주세요.";
		} else if ((num < 0 && eng < 0) || (eng < 0 && spe < 0) || (spe < 0 && num < 0)) {
			msg = "영문, 숫자, 특수문자 중 2가지 이상을 혼합하여 입력해주세요.";
		} else {
			return true;
		}
	}

	if (showAlert) {
		SweetAlert.fire({
			title: "비밀번호 확인",
			html: msg,
			icon: "warning",
			allowOutsideClick: false,
			showCancelButton: false
		});
	}

	return false;
};

export const checkLogin = async (hideAlert?: boolean, checkPath?: string, cancelButton?: boolean): Promise<boolean | ObjType> => {
	const path = checkPath || window.location.pathname;
	if (!path) return true;

	const publicPath = /^\/$|^\/readme|^\/forum\/?$|^\/forum\/view|^\/noti\/?$|^\/noti\/view/.test(path);
	let login = false;

	await signApi
		.get("/main/userinfo?type=checkLogin", {
			withCredentials: true
		})
		// await publicApi
		//   .get("/userinfo?type=developers", {
		//     withCredentials: true,
		//   })
		.then(async ({ data }) => {
			if (
				!publicPath &&
				((/^\/administration\//.test(path) && !checkAdmin(data.role)) ||
					(/^\/(my-apps|application|user-info|forum\/edit)/.test(path) && !checkMember(data.role)) ||
					(/^\/application\/ip-block/.test(path) && !checkPjtAdmin(data.role)))
			) {
				SweetAlert.fire({
					title: "권한 없음",
					html: "접근 권한이 없습니다.",
					icon: "warning",
					showCancelButton: false,
					confirmButtonText: "확인",
					allowOutsideClick: false
				}).then(() => {
					if (window.location.pathname !== "/") window.location.replace("/");
				});
				login = false;
			} else {
				login = data;
			}
		})
		.catch(async (err) => {
			login = false;
			if (err.response?.data?.errorCode === "404") {
				SweetAlert.fire({
					title: err.response.data.errorMessage,
					html: `<pre>APIM 관리자가 "APIM 사용자관리"(Tenant Manager Console)를 통해\n현재 접속을 시도한 사용자 SSO 계정에 대해\n"SSO 사용자 가져오기" 및 적절한 APIM/Developers 권한을 부여해야 합니다.</pre>`,
					icon: "error",
					showCancelButton: false,
					confirmButtonText: "확인",
					allowOutsideClick: false
				});
			} else if (!publicPath && !hideAlert) {
				if (loginAlertShow) return;

				loginAlertShow = true;
				SweetAlert.fire({
					title: "로그인 필요",
					html: "로그인을 해주세요.",
					icon: "warning",
					showCancelButton: true,
					cancelButtonText: cancelButton ? "취소" : "메인으로",
					confirmButtonText: "로그인",
					allowOutsideClick: false
				}).then(({ isConfirmed }) => {
					loginAlertShow = false;
					if (isConfirmed) {
						window.location.replace("/signin?redirectUrl=" + window.location.href);
					} else if (!cancelButton) {
						window.location.replace("/");
					}
				});
			}
		});

	return login || publicPath;
};
