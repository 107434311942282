const GET = "userAPI/GET" as const;
const SET = "userAPI/SET" as const;

export const getUserAPI = () => ({
  type: GET,
});

export const setUserAPI = (data: ENVTYPE) => ({
  type: SET,
  payload: data,
});

type userAction = ReturnType<typeof getUserAPI> | ReturnType<typeof setUserAPI>;

type userState = {
  data: ENVTYPE;
};

const initialState: userState = {
  data: {
    APIM_BASIC_LOGIN_HOSTNAME: "",
    APIM_BASIC_LOGIN_HOSTNAME_PUBLIC: "",
    APIM_AUTH_HOSTNAME: "",
    APIM_TENANT_MANAGER_CONSOLE_URL: "",
    APIM_CONSOLE_URL: "",
    APIM_KIBANA_URL: "",
    APIM_SPRING_PROFILES_ACTIVE: "",
    APIM_HOST: "",
    APIM_ZCP_HOST: "",
    APIM_EMAIL_SERVER_TYPE: "",
    APIM_API_KEY_NAME: "",
  },
};

function userAPI(
  state: userState = initialState,
  action: userAction
): userState {
  switch (action.type) {
    case SET:
      return { data: state.data = action.payload };
    case GET:
      return { data: state.data };
    default:
      return state;
  }
}

export default userAPI;
