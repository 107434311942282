import React, { Component } from 'react';

interface Props {
	position?: 'top' | 'bottom';
	text: string;
}

/**
 * @description "position" 기본값 : "top" 
 */
class SKTooltip extends Component<Props> {
	render() {
		return (
      <div className={`sk-tooltip-inner${this.props.position === 'bottom' ? '' : ' sk-tl-top'}`}>
        <span className="sk-tooltip-text">{this.props.text}</span>
      </div>
		);
	}
}

export default SKTooltip;
