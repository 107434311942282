import React, { Component } from 'react'
import LayoutNavbar from './common/LayoutNavbar'
import LayoutFooter from './common/LayoutFooter'
import Breadcrumbs from './common/Breadcrumbs';

interface Props {
  location: any;
  history: any;
}

class LayoutMain extends Component<Props> {
  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav dp">
        <div className="layout-inner">
          <LayoutNavbar sidenavToggle={false} {...this.props} />
          <div className="layout-container">
            <div className="layout-content bg-white">
              <div className="container-fluid flex-grow-1 container-p-y">
                <div className="breadcrumb-wrap">
                  <Breadcrumbs {...this.props} />
                </div>
                <div className="sub">
                  <div className="layout-sub-inner">
                    <div className="content">
                      {this.props.children}
                    </div>
                  </div>

                </div>
              </div>
              <LayoutFooter {...this.props} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LayoutMain
