const GET = 'sample/GET' as const;
const SET = 'sample/SET' as const;

export const get = () => ({
  type: GET
});

export const set = (data: ObjType[]) => ({
  type: SET,
  payload: data
});

type SampleAction =
  | ReturnType<typeof get>
  | ReturnType<typeof set>;

type sampleState = {
  data: ObjType[];
};

const initialState: sampleState = {
  data: []
};

function sample(
  state: sampleState = initialState,
  action: SampleAction
): sampleState {
  switch (action.type) {
    case SET:
      return { data: state.data = action.payload };
    case GET:
      return { data: state.data };
    default:
      return state;
  }
}

export default sample;