import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { RootState } from "../reducers";
import { setModal, ModalType } from "../reducers/actions/modal";

interface Props {
  modals?: ModalType;
  dispatchModal: any;
}

interface State {
  show: boolean;
  confirmWithoutEnterKey: boolean;
  closeMethod?: Function;
}

class ModalTemplate extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.onModalClose = this.onModalClose.bind(this);
    this.onModalConfirm = this.onModalConfirm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      show: false,
      confirmWithoutEnterKey: false,
    };
  }
  componentDidMount() {
    // 컴포넌트가 마운트될 때 키다운 이벤트 리스너 추가
    document.addEventListener('keydown', this.handleKeyDown.bind(this));
  }

  componentWillUnmount() {
    // 컴포넌트가 언마운트될 때 키다운 이벤트 리스너 제거
    document.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  componentWillReceiveProps(nextProps: Props) {
    if (!nextProps.modals) return false;

    this.setState({
      show: nextProps.modals.show,
      confirmWithoutEnterKey: nextProps.modals.confirmWithoutEnterKey,
    });

    if (nextProps.modals.show) {
      if (typeof nextProps.modals.showMethod === "function") nextProps.modals.showMethod();
    }
    if (nextProps.modals.closeMethod){
      this.setState({
        closeMethod: nextProps.modals.closeMethod,
      })
    }
  }

  onModalClose() {
    this.setState({ show: false });
    this.props.dispatchModal({
      show: false,
    });
    if (this.state.closeMethod){
      this.state.closeMethod();
    }

  }


  handleKeyDown(e: any){
    if(e.key === 'Enter' && this.state.confirmWithoutEnterKey){
      e.preventDefault();
    }
    if(e.key === 'Escape'){
      e.preventDefault();
      this.onModalClose();
    }
  }

  async onModalConfirm(event: React.FormEvent) {
    event.preventDefault();

    if (this.props.modals?.confirmMethod) {
      await this.props.modals.confirmMethod();
    } else {
      this.setState({ show: false });
    }

    if (this.props.modals?.hideClose) {
      this.setState({ show: false });
    }
  }

  render() {
    return (
      <Modal
        className={`modal-${this.props.modals?.type}`}
        show={this.state.show}
        size={this.props.modals?.size || "lg"}
        onHide={() => !!this.props.modals?.allowOutsideClick && this.onModalClose}
      >
        <form onSubmit={this.onModalConfirm.bind(this)} onKeyDown={this.handleKeyDown.bind(this)}>
          <Modal.Header>
            <Modal.Title as="h5">
              {this.props.modals?.title}
              <br />
              <small className="text-muted">{this.props.modals?.desc}</small>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* 모달 내용 */}
            {typeof this.props.modals?.children === 'function' ? this.props.modals.children() : this.props.modals?.children}
          </Modal.Body>
          <Modal.Footer>
            {!this.props.modals?.hideClose &&
            <Button variant="default" onClick={this.onModalClose}>
              취소
            </Button>
            }
            <Button type="submit" variant="primary">
              {this.props.modals?.confirmText || "확인"}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    );
  }
}

export default connect(
  (state: RootState) => ({
    modals: state.modal.modals,
  }),
  (dispatch) => ({
    dispatchModal: (amount: ModalType) => dispatch(setModal(amount)),
  })
)(ModalTemplate);
// export default ModalTemplate;
