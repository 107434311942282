import React, { Component } from 'react'
import Router from './shared/Router'
import './App.scss'
import { ToastTemplate, ModalTemplate } from './components'

class App extends Component {

  render() {
    return (
      <React.Fragment>
        {/* toast */}
        <div id="_toast">
          <ToastTemplate></ToastTemplate>
        </div>

        {/* modal */}
        <ModalTemplate></ModalTemplate>

        {/* router */}
        <Router />
      </React.Fragment>
    )
  }
}

export default App
