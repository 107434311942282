import React, { Component } from "react";
import { connect } from "react-redux";
import Sidenav from "../../../vendor/sidenav";
import { checkLogin, checkPjtAdmin } from "../../../utils/auth";
import { RootState } from "../../../reducers";
import devApi from "../../../apis";

type SideType = "vertical" | "horizontal";

interface Props {
  orientation: SideType;
  sidenavBg: string;
  user: any;
  location?: Location;
  history?: any;
  admin: boolean;
  userAPI?: ENVTYPE;
  settingInfo: SettingInfoType;
}

interface State {
  ipwhitelistEnabled: Boolean;
}

class LayoutSidenav extends Component<Props, State> {
  static defaultProps: { orientation: SideType; sidenavBg: string };

  constructor(props: any) {
    super(props);
    this.state = {
      ipwhitelistEnabled: false,
    };
  }

  async componentDidMount() {
    await devApi.get(`/admin/getSettingList`).then(({ data }) => {
      // IP/Referer Whitelist
      this.setState({
        ipwhitelistEnabled:
          (Array.isArray(data) ? data : []).filter(
            (v: any) => v.type === "ip_whitelist" && v.config?.active
          )?.length > 0,
      });
    });
  }

  layoutSidenavClasses() {
    let bg = this.props.sidenavBg;

    if (
      this.props.orientation === "horizontal" &&
      (bg.indexOf(" sidenav-dark") !== -1 ||
        bg.indexOf(" sidenav-light") !== -1)
    ) {
      bg = bg
        .replace(" sidenav-dark", "")
        .replace(" sidenav-light", "")
        .replace("-darker", "")
        .replace("-dark", "");
    }

    return (
      `bg-${bg} ` +
      (this.props.orientation !== "horizontal"
        ? "layout-sidenav"
        : "layout-sidenav-horizontal container-p-x flex-grow-0")
    );
  }

  toggleSidenav(e: MouseEvent) {
    e.preventDefault();
  }

  isMenuActive(url: string) {
    return this.props.location?.pathname.indexOf(url) === 0;
  }

  isMenuOpen(url: string) {
    return (
      this.props.location?.pathname.indexOf(url) === 0 &&
      this.props.orientation !== "horizontal"
    );
  }

  async movePath(event: any, path: string) {
    event.stopPropagation();
    event.preventDefault();

    if (!(await checkLogin())) return false;

    if (/^statistics/.test(path)) {
      window.open(this.props.userAPI?.APIM_KIBANA_URL);
    } else {
      this.props.history?.push(path);
    }
  }

  setMenu(array: any = []) {
    const setting = [];

    if (array.length > 0) {
      for (let i = 0; i < array.length; i++) {
        if (array[i].name === "home" && array[i].enable === 1) {
          setting.push({ path: "/administration/home", title: "Home 관리" });
        }
        if (array[i].name === "menu" && array[i].enable === 1) {
          setting.push({ path: "/administration/menu", title: "Menu 관리" });
        }
        if (array[i].name === "product" && array[i].enable === 1) {
          setting.push({
            path: "/administration/product",
            title: "Product 관리",
          });
        }
        if (array[i].name === "api" && array[i].enable === 1) {
          setting.push({
            path: "/administration/api-request",
            title: "API 사용 요청 관리",
          });
        }
        if (array[i].name === "terms" && array[i].enable === 1) {
          setting.push({
            path: "/administration/termsConditons",
            title: "약관 관리",
          });
        }
      }
      setting.push({ path: "/administration/setting", title: "Setting" });
    }

    return setting;
  }

  render() {
    // const query = this.props.location?.search || '';
    // let setting;
    // const settingArray: any = this.props.settingInfo || [];
    // const newItems = settingArray.length > 0 ? this.setMenu(settingArray) : [];
    // const items = !this.props.admin ? [
    //     { path: '/application/info', title: '기본 정보'},
    //     { path: '/application/api-key', title: 'API KEY'},
    //     { path: '/application/api-list', title: 'API 사용'},
    //     { path: '/application/member-list', title: '멤버 관리'},
    //     // { path: '/application/statistics', title: 'API 통계' },
    //     // { path: '/application/ip-block', title: 'IP/Referer Whitelist'},
    // ] : settingArray.length > 0 ? newItems :
    // [
    //     { path: '/administration/home', title: 'Home 관리' },
    //     { path: '/administration/menu', title: 'Menu 관리' },
    //     { path: '/administration/product', title: 'Product 관리' },
    //     { path: '/administration/api-request', title: 'API 사용 요청 관리' },
    //     { path: '/administration/termsConditons', title: '약관 관리' },
    //     { path: '/administration/setting', title: 'Setting' },
    // ]

    const query = this.props.location?.search || "";
    const items = (!this.props.admin
      ? [
        { path: "/application/info", title: "기본 정보" },
        { path: "/application/api-key", title: "API KEY" },
        { path: "/application/api-list", title: "API 사용" },
        { path: "/application/member-list", title: "멤버 관리" },
        // { path: '/application/statistics', title: 'API 통계' },
      ].concat(
        checkPjtAdmin(this.props.user?.role) && this.state.ipwhitelistEnabled
          ? [{ path: "/application/ip-block", title: "IP/Referer Whitelist" }]
          : []
      )
      : [
        { path: "/administration/home", title: "Home 관리" },
        { path: "/administration/menu", title: "Menu 관리" },
        // { path: '/administration/domain', title: 'Domain' },
        { path: "/administration/product", title: "Product 관리" },
        // { path: '/administration/application', title: 'Application' },
        { path: "/administration/api-request", title: "API 사용 요청 관리" },
        // { path: '/administration/statistics', title: 'API 통계' },
        // { path: '/administration/forum',  title: '포럼' },
        // { path: "/administration/termsConditons", title: "약관 관리" },
        { path: "/administration/setting", title: "설정" },
      ])

    return (
      // @ts-ignore
      <Sidenav
        orientation={this.props.orientation}
        className={`d-inline-flex bg-white align-top }`}
      >
        <div className="sidenav-inner py-1">
          {/* <Sidenav.Menu icon="" linkText="Pages" badgeText="59"  badgeVariant="primary" active={this.isMenuActive('/pages')} open={this.isMenuOpen('/pages')}>
                    <Sidenav.Menu linkText="Articles" active={this.isMenuActive('/pages/articles')} open={this.isMenuOpen('/pages/articles')}>
                        <Sidenav.RouterLink to="/pages/articles/list">List</Sidenav.RouterLink>
                        <Sidenav.RouterLink to="/pages/articles/edit">Edit</Sidenav.RouterLink>
                    </Sidenav.Menu>
                </Sidenav.Menu> */}
          {items.map((v) => {
            return (
              <Sidenav.Link
                key={v.path}
                href="#"
                onClick={(event: any) => this.movePath(event, v.path + query)}
                active={this.isMenuActive(v.path)}
              >
                {v.title}
              </Sidenav.Link>
            );
          })}
        </div>
      </Sidenav>
    );
  }
}

LayoutSidenav.defaultProps = {
  orientation: "vertical",
  sidenavBg: "sidenav-light",
};

export default connect(
  (state: RootState) => ({
    userAPI: state.userAPI.data,
    settingInfo: state.setting.settingInfo,
    user: state.user.data,
  }),
)(LayoutSidenav);
