import { baseURL } from "../../apis"

const GET_INFO = 'terms/GET_INFO' as const;
const SET_INFO = 'terms/SET_INFO' as const;



export const getTermsInfo = () => ({ type: GET_INFO })
export const setTermsInfo = (termsInfo: TermsInfoType) => ({ type: SET_INFO, payload: termsInfo })

type termsActionType = ReturnType<typeof getTermsInfo> | ReturnType<typeof setTermsInfo>;

type termsState = {
  termsInfo: TermsInfoType;
};

const initialState: termsState = {
  termsInfo: {
    termsGroup: [],
    termsDetail: [],
  }
};

function terms(state: termsState = initialState, action: termsActionType): termsState {
  switch (action.type) {
    case SET_INFO:
      return { termsInfo: action.payload };
    case GET_INFO:
      return { termsInfo: state.termsInfo };
    default:
      return state;
  }
}

export default terms;