import React, { Component } from "react";
import LayoutNavbar from "./common/LayoutNavbar";
import LayoutFooter from "./common/LayoutFooter";
import LayoutSidenav from "./common/LayoutSidenav";
import Breadcrumbs from "./common/Breadcrumbs";

interface Props {
  location: any;
  history: any;
  title: string;
  settingInfo : SettingInfoType;
}




class LayoutAdmin extends Component<Props> {
  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav dp">
        <div className="layout-inner">
          <LayoutNavbar admin={true} sidenavToggle={false} {...this.props} />
          <div className="layout-container">
            <div className="layout-content bg-white">
              <div className="container-fluid flex-grow-1 container-p-y">
                <div className="breadcrumb-wrap">
                  <Breadcrumbs {...this.props} />
                </div>
                <div className="sub">
                  <div className="layout-sub-inner">
                    <div className="sidenav">
                      <LayoutSidenav admin={true} {...this.props} />
                    </div>
                    <div className="content admin-content">
                      {/* title */}
                      <h4 className="mt-3">{this.props.title}</h4>

                      {/* Divider */}
                      <hr />

                      {/* contents */}
                      {this.props.children}
                    </div>
                  </div>
                </div>
              </div>
              <LayoutFooter {...this.props} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LayoutAdmin;
