import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav, Dropdown, NavDropdown, Row, Col } from 'react-bootstrap';
import Icon from '@mdi/react';
import { mdiAccountOutline, mdiCog, mdiLogoutVariant, mdiAccountCogOutline } from '@mdi/js';
import MegaMenu from './MegaMenu';
import { checkDevAdmin, checkAdmin, checkLogin, checkAPIM } from '../../../utils/auth';
import devApi, { signApi, baseURL, publicApi } from '../../../apis';
import { connect } from 'react-redux';
import { RootState } from '../../../reducers';
import { setUser } from '../../../reducers/actions/user';
import { setAppInfo } from '../../../reducers/actions/apps';
import { setHomeInfo } from '../../../reducers/actions/home';
import { setProducts } from '../../../reducers/actions/products';
import { setSettingInfo } from "../../../reducers/actions/setting";
import { apim_logs } from '../../../apim_logs';
import { Avatar } from '../../../components';
// import masking from "../../../utils/masking";

interface Props {
	sidenavToggle: boolean;
	history?: any;
	userAPI?: ENVTYPE;
	user?: UserType;
	homeInfo?: HomeInfoType;
	settingInfo: any,
	dispatchUser?: any;
	dispatchAppInfo?: any;
	dispatchHomeInfo?: any;
	dispatchProducts?: any;
	dispatchSettingInfo: any;
}

interface State {
	menu: Record<string, any>[];
}

class LayoutNavbar extends Component<Props, State> {
	public static defaultProps = {
		sidenavToggle: false,
		admin: false,
	};

	constructor(props: any) {
		super(props);
		this.state = {
			menu: [],
		};
	}
	// 하위 메뉴 만들기
	_createMenu(nodes: any, menu: any) {
		if (nodes && nodes.length > 0) {
			nodes.forEach((v: any) => {
				if (v.state?.hide !== true) {
					const data: any = { title: v.text };
					menu.push(data);
					if(v.originText && (v.originText == "My Applications" || v.originText =="공지사항"||v.originText == "개발자 포럼")){
						// console.log("mjmjmjmjjmj-v", v)
						if(v.originText == "My Applications"){
							data.path = '/my-apps'
						}else if(v.originText =="공지사항"){
							data.path = '/noti'
						}else if(v.originText =="개발자 포럼"){
							data.path = '/forum'
						}
					}else if (v.ppid) {
						data.path = '/readme?page=' + v.id + '&name=' + v.text;
					} else {
						data.children = [];
						if (v.children.length > 0) this._createMenu(v.children, data.children);
					}
				}
			});
		}
	}

	async componentDidMount() {
		// if (/^\/administration/.test(window.location.pathname)) return;

		let settings: any = [];
		try {
			await devApi
				.get(`/admin/getSettingList`)
				.then(({ data }: any) => {
					if (data.length > 0) {
						settings = data;
						this.props.dispatchSettingInfo(data);
					}
				})
				.catch((err: any) => {
					console.warn("[get.termsConditonsDetailList.error]", err);
				});
			
			// public setting
			if (settings.length === 0) {
				await publicApi
					.get(`/env/setting`)
					.then(({ data }:any) => {
						if (data.length > 0) {
							settings = settings.concat(data);
							this.props.dispatchSettingInfo(settings);
						}
				})
				.catch((err: any) => {
					console.warn("[get.env.public.error]", err);
				});
			}
		} catch (err: any) {
			//
		}

		// devApi.get(`/admin/menu`)
		publicApi
			.get(`/menu`)
			.then(({ data }) => {
				const menuData: Record<string, any>[] = [];
				const readmeData: any = {};
				// console.log("mj-add-menu-data", data)
				data.forEach((v: any) => {
					const menu = (readmeData[v.text] = []);
					if (v.children.length > 0 && v.state?.hide !== true) {
						// console.log("mj-menu", menu)
						// console.log("mj-v.children", v.children)
						this._createMenu(v.children, menu);
					}
				});

				const forum_activation = settings?.filter((v: any) => v.type === "forum_activation");
				// console.log("mjmj-readmeData", readmeData)
				// const Forum = forum_activation.length > 0 && forum_activation[0].config?.active ? {
				// 	Forum: [
				// 		{
				// 			title: 'Forum',
				// 			children: [
				// 				{
				// 					title: '개발자 포럼',
				// 					path: '/forum',
				// 				},
				// 				{
				// 					title: '공지사항',
				// 					path: '/noti',
				// 				},
				// 			],
				// 		},
				// 	],
				// } : {}

				const items = {
					...readmeData
				};

				Object.keys(items).map((k1: string) => menuData.push({ title: k1, children: items[k1] }));
				this.setState({
					menu: menuData,
				});
			})
			.catch((err: any) => {
				console.warn(`[Error.menuList] ${err}`);
			});
	}

	// adminLogin() {
	//   window.location.href = `${this.props.userAPI?.APIM_BASIC_LOGIN_HOSTNAME as string}/auth/login?redirectUrl=${window.location.href}`
	// }

	userLogin() {
		this.props.history?.push({
			pathname: '/signin',
			search: 'redirectUrl=' + window.location.href,
		});
	}

	logout() {
		const PROFILES = this.props.userAPI?.APIM_SPRING_PROFILES_ACTIVE as string; // dpback 환경변수
		let url = '';
		let request = signApi.post;
		if (this.props.user?.type === 'developers') {
			url = `${this.props.userAPI?.APIM_AUTH_HOSTNAME}/basic/logout`;
		} else {
			if (PROFILES && PROFILES.indexOf && PROFILES.indexOf('k8s_okta') > -1) {
				request = signApi.get;
				url = `${this.props.userAPI?.APIM_BASIC_LOGIN_HOSTNAME}/api/auth/my/logout`;
			} else {
				url = `${this.props.userAPI?.APIM_BASIC_LOGIN_HOSTNAME}/auth/logout`;
			}
		}

		// (1) APIM Tenant Manager Session 로그아웃
		request(url)
			// @todo
			.then((res) => {
				// res.data === http://xxxxx.xxxx.xxx (Okta 로그아웃 URL)
				// (2) Okta 로그아웃 URL 호출로 Okta 로그아웃 시킨다.
				if (PROFILES && PROFILES.indexOf && PROFILES.indexOf('k8s_okta') > -1) {
					window.location.replace(res.data);
				} else {
					window.location.replace('/');
				}
			})
			.catch((err) => {
				console.warn('[Logout.error]' + err);
			})
			.finally(() => {
				this.props.dispatchUser(null);
				this.props.dispatchAppInfo(null);
				this.props.dispatchHomeInfo(null);
				this.props.dispatchProducts(null);
			});
	}

	async mypage() {
		await checkLogin(false, '/user-info');
		this.props.history?.push('/user-info');
	}

	async movePath(event: any, path: string, newLink?: boolean) {
		event.stopPropagation();
		event.preventDefault();

		if (!(await checkLogin(false, path))) return false;
		if (newLink) window.open(path);
		else this.props.history?.push(path);
	}

	createConsoleButtons() {
		const admin = checkAdmin(this.props.user?.role);
		const devAdmin = checkDevAdmin(this.props.user?.role);
		const apim = checkAPIM(this.props.user?.role);
		const adminPath = /^\/administration/.test(window.location.pathname);
		let btn;

		if (admin && !adminPath) {
			btn = (
				<Nav variant="pills" className="nav-btn" defaultActiveKey="link-1">
					<Nav.Item onClick={(event: any) => this.movePath(event, '/administration/home')}>
						<Nav.Link eventKey="link-1" href="#">
							Developers 관리자
						</Nav.Link>
					</Nav.Item>
				</Nav>
			);
		} else if (apim && adminPath) {
			btn = (
				<Nav variant="pills" className="nav-btn" defaultActiveKey="link-2">
					<Nav.Item onClick={(event: any) => this.movePath(event, '/')}>
						<Nav.Link eventKey="link-2" href="#">
							Developers
						</Nav.Link>
					</Nav.Item>
					<Nav.Item onClick={(event: any) => this.movePath(event, this.props.userAPI?.APIM_CONSOLE_URL as string, true)}>
						<Nav.Link eventKey="link-1" href="#">
							APIM
						</Nav.Link>
					</Nav.Item>
					<Nav.Item onClick={(event: any) => this.movePath(event, this.props.userAPI?.APIM_TENANT_MANAGER_CONSOLE_URL as string, true)}>
						<Nav.Link eventKey="link-1" href="#">
							사용자관리
						</Nav.Link>
					</Nav.Item>
				</Nav>
			);
		} else if (devAdmin && adminPath) {
			btn = (
				<Nav variant="pills" className="nav-btn" defaultActiveKey="link-2">
					<Nav.Item onClick={(event: any) => this.movePath(event, '/')}>
						<Nav.Link eventKey="link-2" href="#">
							Developers
						</Nav.Link>
					</Nav.Item>
					<Nav.Item onClick={(event: any) => this.movePath(event, this.props.userAPI?.APIM_TENANT_MANAGER_CONSOLE_URL as string, true)}>
						<Nav.Link eventKey="link-1" href="#">
							사용자관리
						</Nav.Link>
					</Nav.Item>
				</Nav>
			);
		} else if (adminPath) {
			btn = (
				<Nav variant="pills" className="nav-btn" defaultActiveKey="link-2">
					<Nav.Item onClick={(event: any) => this.movePath(event, '/')}>
						<Nav.Link eventKey="link-2" href="#">
							Developers
						</Nav.Link>
					</Nav.Item>
				</Nav>
			);
		}

		return btn;
	}

	render() {
		const user = this.props.user;

		return (
			<div className="nav_wrapper">
				<Navbar bg="navbar-theme" expand="lg" className="layout-navbar align-items-lg-center container-p-x">
					<Navbar.Brand as={NavLink} to="/" className="app-brand">
						<span className="app-brand-logo">{this.props.homeInfo?.logo !== undefined && <img src={this.props.homeInfo.logo ? baseURL + this.props.homeInfo.logo : `${process.env.PUBLIC_URL}/img/logo.svg`} alt="" />}</span>
					</Navbar.Brand>

					<Navbar.Toggle />

					<Navbar.Collapse className="nav-wrap">
						<Nav className="align-items-lg-center dp-nav">
							<hr className="d-lg-none w-100 my-2" />
							{Array.isArray(this.state.menu) &&
								this.state.menu.map((key: any, index: number) => {
									if (key.title !== '' && key.children.length > 0) {
										return (
											<Nav.Item key={index}>
												<MegaMenu as={NavDropdown} title={key.title} openOnHover={true}>
													<div className="bg-1">
														<Row className="ui-bordered megamenu-items px-3 py-2">
															{Array.isArray(key.children) &&
																key.children.map((v: any, vIndex: number) => {
																	return (
																		<Col sm={6} lg={3} key={`v${vIndex}`}>
																			<div className="menu-title">{v.title}</div>
																			<ul>
																				{Array.isArray(v.children) &&
																					v.children.map((c: any, cIndex: number) => {
																						return (
																							<li key={`c${cIndex}`}>
																								<a onClick={(event) => this.movePath(event, c.path)} href={`${c.path}`}>
																									{c.title}
																								</a>
																							</li>
																						);
																					})}
																			</ul>
																		</Col>
																	);
																})}
														</Row>
													</div>
												</MegaMenu>
											</Nav.Item>
										);
									}
								})}
						</Nav>

						<Nav className="align-items-lg-center dp-tools">
							{this.createConsoleButtons()}

							{/* Divider */}
							<div className="nav-item bar">|</div>

							{/* UserInfo */}
							<Dropdown as={Nav.Item} className="sk-navbar-user">
								<Dropdown.Toggle as={Nav.Link} className="mr-0">
									<span className="d-inline-flex flex-lg-row-reverse align-items-center align-middle">
										<Avatar className="ui-w-30" user={user}/>
										{/* <span className="img-user"></span> */}
										<span className="user">{!user?.id ? '로그인' : ''}</span>
									</span>
								</Dropdown.Toggle>

								<Dropdown.Menu className="dropdown-menu-right">
									{!user?.id && (
										<Fragment>
											{/* <Dropdown.Item onClick={this.adminLogin.bind(this)}><Icon path={mdiCog} size={0.7} /> &nbsp; 관리자 로그인</Dropdown.Item> */}
											<Dropdown.Item onClick={this.userLogin.bind(this)}>
												<Icon path={mdiAccountOutline} size={0.7} /> &nbsp; 로그인
											</Dropdown.Item>
										</Fragment>
									)}
									{/* <Dropdown.Divider /> */}
									{!!user?.id && (
										<Fragment>
											<span className="menu-user-id">{user.id}</span>
											{user.type === 'developers' && (
												<Dropdown.Item onClick={this.mypage.bind(this)}>
													<Icon path={mdiAccountCogOutline} size={0.7} /> &nbsp; 내 정보
												</Dropdown.Item>
											)}
											<Dropdown.Item onClick={this.logout.bind(this)}>
												<Icon path={mdiLogoutVariant} size={0.7} /> &nbsp; 로그아웃
											</Dropdown.Item>
										</Fragment>
									)}
								</Dropdown.Menu>
							</Dropdown>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			</div>
		);
	}
}

export default connect(
	(state: RootState) => ({
		userAPI: state.userAPI.data,
		user: state.user.data,
		homeInfo: state.home.homeInfo,
		settingInfo: state.setting.settingInfo,
	}),
	(dispatch) => ({
		dispatchUser: (amount: UserType) => dispatch(setUser(amount)),
		dispatchAppInfo: (amount: any) => dispatch(setAppInfo(amount)),
		dispatchHomeInfo: (amount: any) => dispatch(setHomeInfo(amount)),
		dispatchProducts: (amount: any) => dispatch(setProducts(amount)),
		dispatchSettingInfo: (amount: SettingInfoType) => dispatch(setSettingInfo(amount)),
	})
)(LayoutNavbar);
