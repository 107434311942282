import React, { Component } from 'react'
import { connect } from 'react-redux';
import LayoutNavbar from './common/LayoutNavbar'
import LayoutFooter from './common/LayoutFooter'
import Breadcrumbs from './common/Breadcrumbs';
import { RootState } from '../../reducers'
import { publicApi } from "../../apis";
import { apim_logs } from "../../apim_logs";
import { setProducts } from "../../reducers/actions/products";
import { setToast } from "../../reducers/actions/toast";
import "../../views/Forum/Styles/Main.scss";

interface Props {
  location: any;
  history: any;
  title: string;
  appInfo: AppInfoType;
  dispatchProducts: any;
  dispatchToast: any;
}
interface State {
  menu: any;
  forumName: String;
  notiName: String
}

class LayoutApps extends Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      menu: [],
      forumName: "개발자 포럼",
      notiName: "공지사항",
    };
  }

  async componentDidMount() {
    await this.getProducts();

    publicApi
        .get(`/menu`)
        .then(({data}) => {
          // console.log("mjmjmjmjmj-menu-forum-data", data)
          // console.log("mj-add-menu-data", data)
          let forumName = "개발자 포럼"
          let notiName = "공지사항"
          let forumObj = []


          for(let i=0; i< data.length; i++){
            if(data[i].originText == "Forum"){
                forumObj= data[i].children;
                break;
            }
          }
          let forumArr = forumObj[0].children


          for(let i=0; i< forumArr.length; i++){
              if(forumArr[i].originText == "개발자 포럼"){
                forumName = forumArr[i].text
              }else if(forumArr[i].originText == "공지사항"){
                notiName = forumArr[i].text
              }
          }
          this.setState({
            forumName: forumName,
            notiName: notiName,
          });
        })
        .catch((err: any) => {
          console.warn(`[Error.menuList] ${err}`);
        });
  }

  async getProducts() {
    publicApi.get(`/products`)
      .then((res) => {
        const activeProducts = (res.data || []).filter((v:any) => v.status === 1);
        apim_logs("products", activeProducts)
        this.props.dispatchProducts(activeProducts);
      })
      .catch((err) => {
        apim_logs("product-list-error", err);
        this.props.dispatchToast({
          show: true,
          header: "상품 목록 불러오기 실패",
          body: "Product 목록을 불러오는 동안 에러가 발생하였습니다. [" + err.response?.errorMessage + "]",
          type: "danger",
        });
    })
  }

  render() {

    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav dp">
        <div className="layout-inner">
          <LayoutNavbar admin={false} sidenavToggle={false} {...this.props} />
          <div className="layout-container">
            <div className="layout-content bg-white">

              <div className="container-fluid flex-grow-1 container-p-y">
                <div className="breadcrumb-wrap">
                  <Breadcrumbs {...this.props} />
                </div>
                <div className="sub">

                  <div className="layout-sub-inner forums-wrap">
                    <div className="content">
                      {/* title */}
                      <h4 className="mt-3">{ /noti/.test(window.location.pathname) ? this.state.notiName : this.state.forumName }</h4>

                      {/* contents */}
                      {this.props.children}
                    </div>
                  </div>

                </div>
              </div>
              <LayoutFooter {...this.props} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(
  (state: RootState) => ({ appInfo: state.apps.appInfo }),
  (dispatch) => ({
    dispatchProducts: (amount:any) => dispatch(setProducts(amount)),
    dispatchToast: (amount:any) => dispatch(setToast(amount)),
  })
)(LayoutApps)
