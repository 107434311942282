import React, { Component } from 'react';
import { connect } from 'react-redux';
import LayoutNavbar from './common/LayoutNavbar';
import LayoutFooter from './common/LayoutFooter';
import LayoutSidenav from './common/LayoutSidenav';
import Breadcrumbs from './common/Breadcrumbs';
import { RootState } from '../../reducers';
import { AppInfo, SweetAlert } from '../../components';
import { setAppInfo } from '../../reducers/actions/apps';
import { setModal } from '../../reducers/actions/modal';
import { checkDevAdmin } from '../../utils/auth';
import devApi from '../../apis';

interface Props {
	location: any;
	history: any;
	title: string;
	appInfo: AppInfoType;
	user: any;
	dispatchAppInfo: any;
	dispatchModal: any;
	settingInfo : SettingInfoType;
}

class LayoutApps extends Component<Props> {
	async componentDidMount() {
		const appid = new URLSearchParams(document.location.search).get('appid') as string;
		if (appid && this.props.appInfo?.id) return;
		await this.getAppinfo(appid);
	}

	async getAppinfo(appid: string) {
		let axiosOptions: any = {
			method: 'get',
			url: `/apps`,
			headers: {
				spaceId: appid,
			},
			withCredentials: true,
		};
		try {
			const { data } = await devApi(axiosOptions);
			if (!data) throw Error();
			data.admin = checkDevAdmin(this.props.user?.role) || data.admin;
			await this.props.dispatchAppInfo(data);
		} catch (err) {
			try {
				//@ts-ignore
				let message = err.response.data.message;
				if (message === 'pre:RoleCheckFilter' || message === 'pre:BackendApiCheckFilter') {
					SweetAlert.fire({
						title: '권한 없음',
						html: '접근 권한이 없습니다.',
						icon: 'warning',
						showCancelButton: false,
						confirmButtonText: '확인',
						allowOutsideClick: false,
					}).then(() => {
						window.location.replace('/my-apps');
					});
					return;
				}
			} catch (e) {}
			SweetAlert.fire({
				title: '권한 없음',
				html: '접근 권한이 없습니다.',
				icon: 'warning',
				showCancelButton: false,
				confirmButtonText: '확인',
				allowOutsideClick: false,
			}).then(() => {
				window.location.replace('/my-apps')
			});
		}
	}

	render() {
		return (
			<div className="layout-wrapper layout-1 layout-apps layout-without-sidenav dp">
				<div className="layout-inner">
					<LayoutNavbar admin={false} sidenavToggle={false} {...this.props} />
					<div className="layout-container">
						<div className="layout-content bg-white">
							<div className="container-fluid flex-grow-1 container-p-y">
								<div className="breadcrumb-wrap">
									<Breadcrumbs {...this.props} />
								</div>
								<div className="sub">
									<div className="layout-sub-inner">
										<div className="sidenav">
											<LayoutSidenav admin={false} {...this.props} />
										</div>
										<div className="content">
											{/* title */}
											<h4 className="mt-3">{this.props.title}</h4>

											{/* Divider */}
											<hr />
											{/* card */}
											<AppInfo {...this.props.appInfo}></AppInfo>

											{/* contents */}
											{this.props.children}
										</div>
									</div>
								</div>
							</div>
							<LayoutFooter {...this.props} />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(
	(state: RootState) => ({
		appInfo: state.apps.appInfo,
		user: state.user.data,
	}),
	(dispatch) => ({
		dispatchAppInfo: (amount: any) => dispatch(setAppInfo(amount)),
		dispatchModal: (amount: any) => dispatch(setModal(amount)),
	})
)(LayoutApps);
