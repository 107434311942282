import React, { Component, Fragment, ReactNode, SyntheticEvent } from 'react'
import { Card } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

const { SearchBar } = Search;

interface Props {
  data: ObjType[] | null | undefined;
  columns: ObjType[];
  children?: ReactNode;
  selectRow?: { 
    mode: 'checkbox' | 'radio';
    classes?: 'table-success' | 'table-info' | 'table-danger' | 'table-warning';
    onSelect?: (row: any, isSelected: boolean, rowIndex: number, e: SyntheticEvent<Element, Event>) => boolean | void;
    onSelectAll?: (isSelect: boolean, rows: any, e: SyntheticEvent<Element, Event>) => void | number[] | undefined;
  };
  paginationOption?: ObjType;
  search?: boolean;
  key?: string;
  merms?: ObjType[];
}

class TermsList extends Component<Props> {
  public static defaultProps = {
    search: true,
  };

  state = {
    data: [],
    columns: [],
  };

  static getDerivedStateFromProps(props: Props) {

    const isArr = Array.isArray(props.data);
    if (!isArr) console.warn("[TermsList.data.error] data is not array : " + props.data)
    return {
      data: isArr ? props.data : [],
      columns: props.columns
    };
  }

  render() {
    return (      
      <ToolkitProvider
        keyField={this.props.key || "terms_id"}
        data={this.state.data}
        columns={this.state.columns}
        bootstrap4
        search>
        {props => (<Fragment>
          {/* Search bar */}    
          {this.props.search && <Card.Body className="pt-4 d-flex justify-content-end px-2 py-2">
            <div>
              {/* <Icon path={mdiMagnify} size={0.8} />&nbsp; */}
              <SearchBar {...props.searchProps} />
            </div>
            <div className="py-2 pl-2">
              {this.props.children}
            </div>
          </Card.Body>}

          {/* @ts-ignore */}
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            hover={true}
            wrapperClasses="table-responsive table-striped react-bs-table-card"
            classes="card-table border-top border-bottom mb-3"
            pagination={paginationFactory(this.props.paginationOption as ObjType)}
            selectRow = {this.props.selectRow}
            noDataIndication={ () => !!this.props.data ? "조회된 데이터가 없습니다." : "데이터 조회중..." }
          />
        </Fragment>)}
      </ToolkitProvider>
    )
  }
}

export default TermsList
