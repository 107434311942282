import React, { Component } from "react";
import { Card } from 'react-bootstrap'
import IconSlot from './IconSlot'
import { baseURL } from '../apis'

interface Props extends AppInfoType { 
    product?: boolean;
    description?: string;
}

class AppInfo extends Component<Props> {
    render() {
        const isApp = !this.props.product;
        return (
            <Card body className={`mt-3 bg-light-secondary shoaw-none ${isApp ? "api-card" : "product-card"}`}>
                <div className="my-2"
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        marginRight: '20px'
                    }}>
                    <IconSlot src={baseURL + this.props.icon} />
                </div>
                <div style={{
                    display: 'inline-block',
                    width: 'calc(100% - 60px)'
                }}>
                    <Card.Title as="div" className="with-elements">
                        {

                            isApp ?
                            <h5 className="m-0 mr-2">이름 : {this.props.name}</h5> :
                            <h5 className="m-0 mr-2">{this.props.name}</h5>
                        }
                        {
                            isApp &&
                            <div className="card-title-elements ml-md-auto">
                                생성일 : {this.props.createAt}
                            </div>
                        }
                    </Card.Title>
                    {
                        isApp ?
                        <Card.Text>AppID : {this.props.id}</Card.Text> :
                        <Card.Text>{this.props.description}</Card.Text>
                    }
                </div>
            </Card>
        );
    }
}

export default AppInfo;