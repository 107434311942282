const GET = 'products/GET' as const;
const SET = 'products/SET' as const;

export const getProducts = () => ({ type: GET })
export const setProducts = (data: any) => ({ type: SET, payload: data })

type productsActionType = ReturnType<typeof getProducts> | ReturnType<typeof setProducts>;

type productsState = {
  data: any;
};

const initialState: productsState = {
  data: []
};

function products(state: productsState = initialState, action: productsActionType): productsState {
  switch (action.type) {
    case SET:
      return { data: action.payload };
    case GET:
      return { data: state.data };
    default:
      return state;
  }
}

export default products;