const css = "font-size:12px; color:teal; background:floralwhite; font-weight:bold; border-radius:3px; padding:1px 2px;"

export function apim_logs(arg0: any, arg1?: any) {
  if (process.env.SHOW_LOG !== "ON") return;

  if (arg0 && arg1) {
      console.log(`%c${arg0}`, css, arg1);
  } else if (arg0) {
      console.log(`%c${arg0}`, css);
  }
}

