import { baseURL } from "../../apis"

const FAVICON = document.querySelector("#_favicon_link") as any;
const LOADING = document.querySelector("#_loading_logo") as any;

const GET_INFO = 'home/GET_INFO' as const;
const SET_INFO = 'home/SET_INFO' as const;

export const getHomeInfo = () => ({ type: GET_INFO })
export const setHomeInfo = (homeInfo: HomeInfoType) => ({ type: SET_INFO, payload: homeInfo })

type homeActionType = ReturnType<typeof getHomeInfo> | ReturnType<typeof setHomeInfo>;

type homeState = {
  homeInfo: HomeInfoType;
};

const initialState: homeState = {
  homeInfo: {
    title: "",
    favicon: "",
    carousel: [{}],
    footer: [{}],
    logo: undefined,
    id: "",
    modified_by: "",
    modified_date: "",
    slideTerm: 2,
    terms: [],
    setting: [],
  }
};

function home(state: homeState = initialState, action: homeActionType): homeState {
  switch (action.type) {
    case SET_INFO:
      document.title = action.payload.title;
      FAVICON.href = action.payload.favicon ? baseURL + action.payload.favicon : "";
      LOADING.src = action.payload.logo ? baseURL + action.payload.logo : "";
      return { homeInfo: action.payload };
    case GET_INFO:
      return { homeInfo: state.homeInfo };
    default:
      return state;
  }
}

export default home;