import React, { Component } from 'react'
import './IconSlot.scss'

interface PropsIconSlot {
    src?: string;
    size?: { w: string, h: string;};
    style?: Record<string, string>;
    square?: boolean;
    maxWidth?: string;
}

class IconSlot extends Component<PropsIconSlot> {
    render() {
        const size = this.props.size || {w: "40px", h: "40px"};
        if (this.props.src) {
            return (
                <img src={this.props.src} className={`d-block${this.props.square ? '' : ' rounded-circle'}`} style={{width: size.w, height: size.h, outline: '2px dashed #ccc', maxWidth: this.props.maxWidth || 'none'}} alt="" />
            )
        } else {
            return (
                <div className={`iton-triangle d-block ${this.props.square ? '' : 'rounded-circle'}`} 
                style={{
                    width: size.w,
                    height: size.h, 
                    ...this.props.style
                }}></div>
            )
        }
    }
}
  
export default IconSlot