const GET_INFO = 'commentInfo/GET_INFO' as const;
const SET_INFO = 'commentInfo/SET_INFO' as const;

export const getCommentEditId = () => ({ type: GET_INFO });
export const setCommentEditId = (id: string) => ({ type: SET_INFO, payload: id });

type appActionType = ReturnType<typeof getCommentEditId> | ReturnType<typeof setCommentEditId>;

type appsState = {
	id: string;
};

const initialState: appsState = {
	id: '',
};

function apps(state: appsState = initialState, action: appActionType): appsState {
	switch (action.type) {
		case SET_INFO:
			return { id: action.payload };
		case GET_INFO:
			return { id: state.id };
		default:
			return state;
	}
}

export default apps;
