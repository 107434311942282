const GET_INFO = 'apps/GET_INFO' as const;
const SET_INFO = 'apps/SET_INFO' as const;

export const getAppInfo = () => ({ type: GET_INFO })
export const setAppInfo = (appInfo: AppInfoType) => ({ type: SET_INFO, payload: appInfo })

type appActionType = ReturnType<typeof getAppInfo> | ReturnType<typeof setAppInfo>;

type appsState = {
  appInfo: AppInfoType;
};

const initialState: appsState = {
  appInfo: {
    icon: '',
    id: '',
    createDate: '',
    name: '',
    apiKey: '',
    admin: false,
    organizationId: '',
    createBy: '',
    companyName: '',
    companyHomepage: '',
    type: '',
    status: '',
    label: '',
    organizationName: '',
    systemId: '',
    systemName: '',
    systemUrl: '',

  }
};

function apps(state: appsState = initialState, action: appActionType): appsState {
  switch (action.type) {
    case SET_INFO:
      return { appInfo: action.payload };
    case GET_INFO:
      return { appInfo: state.appInfo };
    default:
      return state;
  }
}

export default apps;