import React, { Component, Fragment, ReactNode } from 'react'
import Icon from '@mdi/react'
import {mdiPlus, mdiMinus, mdiDotsHorizontal, mdiTrashCanOutline, mdiPencil} from '@mdi/js';
import { Badge, Dropdown } from 'react-bootstrap'
import { SKTooltip } from '../components'
import './TreeView.scss'

interface NodeProperties {
  emptyIcon?: string;
  showTags?: boolean;
  visible?: boolean;
}

interface Props extends NodeProperties{
  nodes: any[];
  selectedHandler: Function;
  addNode: Function;
  removeNode: Function;
}

class TreeView extends Component<Props> {
  render() {
    // console.log("mj-treeview", this.props.nodes)
    return (
      <div id='treeview' className='treeview'>
        <ul className='list-group'>
          {
            this.props.nodes?.length > 0 && 
            this.props.nodes.map((v: any, i: number) => {
              return <TreeNode 
                node={v} 
                level={1} 
                visible={true} 
                options={this.props} 
                key={i}
              />
            })
          }
        </ul>
      </div>
    );
  }
};

interface NodeProps extends NodeProperties {
  node: any;
  level: number;
  options: any;
  hide?: boolean;
}

interface NodeState {
  expanded: boolean;
  selected: boolean;
}

class TreeNode extends Component<NodeProps, NodeState> {
  constructor(props: any) {
    super(props);
    this.state = {
      expanded: props.node.state?.expanded,
      selected: props.node.state?.selected,
    }
  }
  
  componentDidUpdate(prevProps: any) {
    const node = this.props.node;
    if (prevProps.node.state?.selected !== node.state?.selected || prevProps.node.state?.expanded !== node.state?.expanded) {
      this.setState({
        expanded: node.state?.expanded,
        selected: node.state?.selected
      })
    }
  }

  toggleExpanded(event: React.MouseEvent) {
    event.stopPropagation();
    this.setState({ expanded: !this.state?.expanded });
  }
  
  toggleSelected(event: React.MouseEvent) {
    event.stopPropagation();
    this.props.options.selectedHandler(this.props.node)
  }

  addNode() {
    this.props.options.addNode(this.props.level, this.props.node.id)
  }

  removeNode() {
    this.props.options.removeNode(this.props.level, this.props.node.id)
  }

  setStyle(node: any, level: any) {
    
    const hide = node.state?.hide === true ? true : false ;
    const nodes = this.props.options.nodes;
    const thisNode = node;
    if(hide === true){
      return '0.6';
    } else {
      if(level > 1){
        return this.changOpacity(nodes, thisNode);
      }
    }    
  }

  changOpacity(nodes: any, thisNode: any){

    const pid = thisNode?.pid;
    const ppid = thisNode?.ppid;

    for(let i=0; i<nodes.length; i++){

      const hide = this.props.hide || nodes[i].state?.hide;

      if ((hide === true && nodes[i].id === pid) || (hide === true && nodes[i].id === ppid)) {
        return '0.6';
      }
      if (nodes[i].children && nodes[i].children.length > 0 ) {
          this.changOpacity(nodes[i].children, thisNode);
      }
    }
  }
 
  render() {
    const node = this.props.node;
    const options = this.props.options;
    const level = this.props.level;

    const style = { 
      display: !this.props.visible ? 'none' : '',
      opacity: this.setStyle(node, level)
    };



    const indents = [];
    for (let i = 0; i < this.props.level-1; i++) {
      indents.push(<span className='indent' key={i}></span>);
    }

    let expandCollapseIcon: ReactNode;
    if (node.children?.length > 0) {
      if (!this.state?.expanded) {
        expandCollapseIcon = (
          <span className="icon sk-tooltip" onClick={this.toggleExpanded.bind(this)} style={{padding:'1px 0 4px 0'}}>
            <Icon path={mdiPlus} className="gray" size={.8} />
            <SKTooltip text='펼치기' position='bottom' />
          </span>
        );
      } else {
        expandCollapseIcon = (
          <span className="icon sk-tooltip" onClick={this.toggleExpanded.bind(this)} style={{padding:'1px 0 4px 0'}}>
            <Icon path={mdiMinus} className="gray" size={.8} />
            <SKTooltip text='접기' position='bottom' />
          </span>
        );
      }
    } else {
      expandCollapseIcon = (
        <span className={options.emptyIcon}></span>
      );
    }

    const nodeText: ReactNode = <span>{node.text}</span>

    let badges: ReactNode;

    if(node.originText){
      badges = <Badge className="small-badge">({node.originText})</Badge>
    }


    if (options.showTags && node.tags) {
      badges = node.tags.map((tag: string, i: number) => {
        return (
          <Badge variant="outline-danger" key={"tag"+i}>{tag}</Badge>
        );
      });
    }

    let children: any;
    if (node.children?.length > 0) {
      children = [];
      node.children.forEach((c: any, i: number) => {
        children.push(<TreeNode 
          hide={this.props.hide || node.state?.hide}
          node={c} 
          level={this.props.level+1} 
          visible={this.state?.expanded && this.props.visible}
          options={options} 
          key={c.id + i}
        />);
      });
    }

    return (
      <Fragment>
        <li className={`list-group-item depth${this.props.level} ${node.state?.selected ? 'selected' : ''}`}
            style={style}
            onClick={this.toggleSelected.bind(this)}
          >
          {indents}
          {expandCollapseIcon}
          {nodeText}
          {badges}
          {node.originText?(
              <div className="tree-more-menu" style={{position: 'relative'}} >
              </div>
              ) : (
              <div className="tree-more-menu" style={{position: 'relative'}} >
                <Dropdown className="dropdown-toggle-hide-arrow" onClick={(event: React.MouseEvent) => event.stopPropagation()}>
                  <Dropdown.Toggle variant="default" size="sm" className="icon-btn borderless md-btn-flat mr-3 sk-tooltip" aria-label='더보기' style={{padding:'2px'}}>
                    <Icon path={mdiDotsHorizontal} size={.7} style={{ float: 'right' }} />
                    <SKTooltip text='더보기' />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {
                        this.props.level < 3 && <Dropdown.Item onClick={() => this.addNode()} className='sk-tooltip'>
                          <Icon path={mdiPlus} color="blue" size={.7} style={{ float: 'left' }} aria-label="추가" />
                          <SKTooltip text='추가' />
                        </Dropdown.Item>
                    }
                    <Dropdown.Item onClick={() => this.removeNode()} className='sk-tooltip'>
                      <Icon path={mdiTrashCanOutline} color="red" size={.7} style={{ float: 'left' }} aria-label="삭제" />
                      <SKTooltip text='삭제' />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
          )}
        </li>
        {children}
      </Fragment>
    );
  }
};


export const createNode = () => {

}

export default TreeView