import React, { Component } from 'react'
import LayoutNavbar from './common/LayoutNavbar'
import LayoutFooter from './common/LayoutFooter'

interface Props {
  location: any;
  history: any;
}

class LayoutMain extends Component<Props> {
 
  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav dp">
        <div className="layout-inner">
          <LayoutNavbar sidenavToggle={false} {...this.props} />

          <div className="layout-container">
            <div className="layout-content">
              <div className="container-fluid flex-grow-1 container-p-y">
                {this.props.children}
              </div>

              <LayoutFooter {...this.props} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default LayoutMain
