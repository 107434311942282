import Axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { openLoading, closeLoading } from "../utils/loading";
import { apim_logs } from "../apim_logs";

function testUrl(config: AxiosRequestConfig) {
  // 로컬 개발용 소스
  if (process.env.NODE_ENV === "production") return config;
  // const publicDPFUrl = "https://mydata-stg-admin-developers.mydata-dev.skcloud.io";
  const publicDPFUrl = "https://developers.apimdemo119.skcloud.io";

  const publicDPFUrlPaths = [
    "/basic/login",
    "/basic/logout",
    "/basic/getPublicKey",
    "/basic/userCheck",
    "/basic/addUser",
    "/basic/password",
    "/basic/securityQuestion",
  ];
  const needPublicUrl = publicDPFUrlPaths.some((path) => {
    return path === config.url;
  });
  if (needPublicUrl) {
    config.url = publicDPFUrl + config.url;
  }
  return config;
}
Axios.interceptors.request.use((config: AxiosRequestConfig) => {
  return testUrl(config);
});

//@ts-ignore
const rsa = new RSAKey();

export const tmProxyPrefix = "/developers-backend/";
// process.env.NODE_ENV === "production" ? "/developers-backend/" : "";
export const baseURL =
  process.env.NODE_ENV === "production"
    ? window.location.protocol +
      "//" +
      window.location.hostname +
      (window.location.port ? ":" + window.location.port : "")
    : window.location.protocol + "//" + window.location.hostname + ":3000";

export const publicApi = Axios.create({
  baseURL: baseURL + "/main",
  timeout: 180000,
});

const instance = Axios.create({
  baseURL: baseURL,
  timeout: 180000,
});

export const getSignAPI = async () => {
  const url = {
    APIM_BASIC_LOGIN_HOSTNAME: "",
    APIM_BASIC_LOGIN_HOSTNAME_PUBLIC: "",
    APIM_AUTH_HOSTNAME: "",
    APIM_TENANT_MANAGER_CONSOLE_URL: "",
    APIM_CONSOLE_URL: "",
    APIM_KIBANA_URL: "",
    APIM_SPRING_PROFILES_ACTIVE: "",
    APIM_HOST: "",
    APIM_ZCP_HOST: "",
    APIM_EMAIL_SERVER_TYPE: "",
    APIM_API_KEY_NAME: ""
  };

  // await signApi
  await publicApi
    .get(`/env?host=${window.location.host}`)
    .then(({ data }) => {
      url.APIM_BASIC_LOGIN_HOSTNAME = data.APIM_BASIC_LOGIN_HOSTNAME.replace(
        /\/$/,
        ""
      );
      url.APIM_BASIC_LOGIN_HOSTNAME_PUBLIC = data.APIM_BASIC_LOGIN_HOSTNAME_PUBLIC.replace(
        /\/$/,
        ""
      );
      url.APIM_TENANT_MANAGER_CONSOLE_URL = data.APIM_TENANT_MANAGER_CONSOLE_URL.replace(
        /\/$/,
        ""
      );
      url.APIM_CONSOLE_URL = data.APIM_CONSOLE_URL.replace(/\/$/, "");
      url.APIM_KIBANA_URL = data.APIM_KIBANA_URL.replace(/\/$/, "");
      url.APIM_SPRING_PROFILES_ACTIVE = data.APIM_SPRING_PROFILES_ACTIVE?.replace(
        /\/$/,
        ""
      );

      // APIM_DEVELOPERS_CONSOLE_URL 의미는 내부망 용 개발자포탈 프론트엔드 도메인
      if (
        // 현재 도메인이 내부망 용 개발자포탈 프론트엔드 도메인 이면
        data.APIM_DEVELOPERS_CONSOLE_URL?.indexOf(window.location.hostname) !==
        -1
      ) {
        // developers 내부용 주소로 접속한 경우, 내부용 로그인 주소 사용
        url.APIM_AUTH_HOSTNAME = url.APIM_BASIC_LOGIN_HOSTNAME;
      } else {
        // developers 외부용 주소로 접속한 경우, 외부용 로그인 주소 사용
        url.APIM_AUTH_HOSTNAME = url.APIM_BASIC_LOGIN_HOSTNAME_PUBLIC;
      }
      url.APIM_AUTH_HOSTNAME = baseURL;
      url.APIM_EMAIL_SERVER_TYPE = data.APIM_EMAIL_SERVER_TYPE;

      url.APIM_HOST = data.APIM_HOST;
      url.APIM_ZCP_HOST = data.APIM_ZCP_HOST;
      url.APIM_API_KEY_NAME = data.APIM_API_KEY_NAME;
    })
    .catch((err) => {
      console.error("[환경 변수 로드 실패] " + err);
    });
  return url;
};

export const signApi = Axios.create({
  baseURL: baseURL,
  timeout: 180000,
  withCredentials: true,
});

function getByteLength(text: string): number {
  if (!text) return 0;

  const encoder = window.encodeURIComponent;
  const cl = new window.TextEncoder().encode(text).length;
  const cr = encoder(text).match(/(%0A|%0D)/gi)?.length || 0;

  return cl + cr;
}

function splitData(config: any): string[] {
  const data: string =
    typeof config.data === "string" ? config.data : JSON.stringify(config.data);
  const byteLen = getByteLength(data);

  rsa.setPublic(config.headers.publicKeyModulus, "10001");
  const baseLen = 240;

  if (byteLen > baseLen) {
    const arr: string[] = [];
    const splitNum = Math.floor(byteLen / (baseLen / 2)) + 1; // 한글은 3byte 감안하여 1/2로...
    for (let i = 0; i < data.length; i = i + splitNum) {
      arr.push(rsa.encrypt(data.substr(i, splitNum)));
    }

    return arr;
  } else {
    return [rsa.encrypt(data)];
  }
}
var crypto = require("crypto");
const chainingMode = "AES-256-CBC";
const encrypt = (utf8Text: string, privateKey: string, ivKey: string) => {
  // apim_logs("chainingMode", chainingMode);
  // apim_logs("privateKey", privateKey);
  // apim_logs("ivKey", ivKey);
  const cipher = crypto.createCipheriv(chainingMode, privateKey, ivKey);
  cipher.setAutoPadding(false);
  let encrypted = cipher.update(pkcs7Pad(utf8Text), undefined, "base64");
  encrypted += cipher.final("base64");
  return encrypted;
};
const decrypt = (base64Text: string, privateKey: string, ivKey: string) => {
  const decipher = crypto.createDecipheriv(chainingMode, privateKey, ivKey);
  decipher.setAutoPadding(false);
  let decrypted = decipher.update(base64Text, "base64", "utf8");
  decrypted += decipher.final("utf8");
  return pkcs7Unpad(decrypted);
};

const pkcs7 = require("pkcs7");

const pkcs7Pad = (params: string) => {
  const buffer = Buffer.from(params, "utf8");
  const bytes = new Uint8Array(buffer.length);
  let i = buffer.length;
  while (i--) {
    bytes[i] = buffer[i];
  }
  return Buffer.from(pkcs7.pad(bytes) as Uint8Array);
};

const pkcs7Unpad = (params: string) => {
  const buffer = Buffer.from(params, "utf8");
  const bytes = new Uint8Array(buffer.length);
  let i = buffer.length;
  while (i--) {
    bytes[i] = buffer[i];
  }
  const result = Buffer.from(pkcs7.unpad(bytes) as Uint8Array);
  return result.toString("utf8");
};

function encryptSymmetricKey(config: any) {
  // https://velog.io/@bang9dev/AES-256-%EC%97%90-%EA%B4%80%ED%95%98%EC%97%AC
  let sk = ""; // 32자리  = 32byte 키 생성
  for (let i = 0; i < 32; i++) {
    sk += Math.floor(Math.random() * 10);
  }
  const ivKey = sk.substring(0, 16); // 16byte
  if (config.data) {
    // apim_logs("encryptSymmetricKey Before config.data", config.data);
    const encrypted = encrypt(JSON.stringify(config.data), sk, ivKey);
    config.data = { data: [encrypted] };
  }

  rsa.setPublic(config.headers.publicKeyModulus, "10001");
  config.headers["x-apim-enc-sk"] = rsa.encrypt(sk);
  config.sk = sk;
  config.ivKey = ivKey;

  // apim_logs("publicKeyModulus", config.headers.publicKeyModulus);
  // apim_logs("publicKeyExponent", "10001");
  // apim_logs("sk", sk);
  // apim_logs("x-apim-enc-sk", config.headers["x-apim-enc-sk"]);
  // apim_logs("x-apim-pk", config.headers["x-apim-pk"]);
  // apim_logs("body", config.data);

  config.headers["content-type"] = "application/json";
  delete config.headers.publicKeyModulus;
}
// function setEnc(config: any) {
//   apim_logs("checkEncTarget");
//   // const encryptTargets = [{ url: "/basic/login", method: "post" }];
//   const encryptTargets = [
//     {
//       url: "https://loadflash-admin-developers.skcloud.io/basic/login",
//       method: "post",
//     },
//   ];
//   const result = encryptTargets.filter((target, i) => {
//     return target.url === config.url && target.method === config.method;
//   });
//   if (result.length > 0) {
//     apim_logs("checkEncTarget setEnc");
//     // config.data = { data: splitData(config) };
//     // config.headers["content-type"] = "application/json";
//     // delete config.headers.publicKeyModulus;

//     encryptSymmetricKey(config);
//   }

//   return config;
// }
function isEmptyObject(param: any) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}
function decryptSymmetricKey(response: any) {
  let obj = response;
  let target = response;
  if (response.response) {
    obj = Object.assign({}, response.response);
    target = response.response;
  }
  if (obj.config?.sk && obj.data && !isEmptyObject(obj.data)) {
    try {
      target.data = JSON.parse(
        decrypt(obj.data, obj.config.sk, obj.config.ivKey)
      );
    } catch (e) {
      apim_logs(e);
    }
  }
}

signApi.interceptors.request.use(async (config) => {
  if (!config.headers["console-loading"]) openLoading();
  config.headers["x-console-type"] = "developers";

  if (process.env.NODE_ENV !== "production") {
    //@ts-ignore
    config.headers["x-user-id"] = window.DEV_USER_ID;
  }

  // apim_logs("config", config);
  // let url   = "";
  try {
    const result = await Axios.get(`/basic/getPublicKey`);
    if (
      !(
        result &&
        result.status === 200 &&
        result?.data?.publicKey &&
        result?.data?.publicKeyModulus
      )
    ) {
      // this.props.dispatchToast({
      //   show: true,
      //   header: "로그인 실패",
      //   body: "인증 보안 모듈 처리 실패.",
      //   type: "danger",
      // });
      throw new Axios.Cancel("인증 보안 모듈 처리 실패");
    }
    config.headers["publicKeyModulus"] = result?.data?.publicKeyModulus;
    config.headers["x-apim-pk"] = result?.data?.publicKey;
  } catch (e) {
    throw new Axios.Cancel("인증 보안 모듈 처리 실패");
  }

  // return config;
  encryptSymmetricKey(config);
  return testUrl(config);
});
publicApi.interceptors.request.use(async (config) => {
  if (!config.headers["console-loading"]) openLoading();
  config.headers["x-console-type"] = "developers";

  if (process.env.NODE_ENV !== "production") {
    //@ts-ignore
    config.headers["x-user-id"] = window.DEV_USER_ID;
  }

  return config;
});

instance.interceptors.request.use(function (config: AxiosRequestConfig) {
  if (!config.headers["console-loading"]) openLoading();
  config.headers["x-console-type"] = "developers";

  if (process.env.NODE_ENV !== "production") {
    //@ts-ignore
    config.headers["x-user-id"] = window.DEV_USER_ID;
  }
  // config = setEnc(config);
  // /user : TM Proxy 미사용

  // /apps :
  // /members

  // /admin
  // apim_logs("config", config);

  let url = config.url || "";
  if (
    url.indexOf("/apps/create") === 0 ||
    url.indexOf("/apps/swagger") === 0 ||
    url.indexOf("/user") === 0
  ) {
    // 세션 체크만 하고 pass
    config.url = `${tmProxyPrefix}org/default/spc/main${url}`;
  } else if (/^\/admin\/|^\/forum/.test(url)) {
    // 세션 체크, 권한 체크 후 pass
    // spcId : all 을 이용하여 orgId 찾지 못한다
    // Path의 orgId : default 사용하여 org 찾는다
    config.url = `${tmProxyPrefix}org/default/spc/all${url}`;
  } else if (url.indexOf("/apps") === 0 || url.indexOf("/members") === 0) {
    // orgId, spcId 추출 및 권한 체크 후 pass
    config.url = `${tmProxyPrefix}org/default/spc/${
      config.headers.spaceId || "null"
    }${url}`;
  } else {
    //
  }

  delete config.headers.spaceId;
  return config;
});

signApi.interceptors.response.use(
  async function (response) {
    // apim_logs("signApi response success", response);
    await decryptSymmetricKey(response);
    closeLoading();
    return response;
  },
  async function (error) {
    apim_logs("signApi response error", error);
    await decryptSymmetricKey(error);
    closeLoading();
    return Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    closeLoading();
    return response;
  },
  function (error) {
    closeLoading();
    return Promise.reject(error);
  }
);
publicApi.interceptors.response.use(
  function (response) {
    closeLoading();
    return response;
  },
  function (error) {
    closeLoading();
    return Promise.reject(error);
  }
);

export default instance;
