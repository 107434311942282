import { ReactNode } from "react";

export type ModalType = {
  show: boolean;
  size?: "sm" | "lg" | "xl";
  type?: "top" | "slide" | "fill-in";
  title?: string;
  desc?: string;
  allowOutsideClick?: boolean;
  children?: ReactNode | Function;
  confirmMethod?: Function;
  closeMethod?: Function;
  showMethod?: Function;
  confirmText?: string;
  hideClose?: boolean;
  focusElement?: ReactNode;
  confirmWithoutEnterKey: boolean;
};

const GET = "modal/GET" as const;
const SET = "modal/SET" as const;

export const getModal = () => ({
  type: GET,
});

export const setModal = (item: ModalType) => ({
  type: SET,
  payload: item,
});

type modalAction = ReturnType<typeof getModal> | ReturnType<typeof setModal>;

type modalState = {
  modals: ModalType;
};

const initialState: modalState = {
  modals: { show: false, confirmText: "확인", confirmWithoutEnterKey: false },
};

function modal(
  state: modalState = initialState,
  action: modalAction
): modalState {
  switch (action.type) {
    case SET:
      // state.toasts.push(action.payload);
      // return { toasts: state.toasts };
      return { modals: action.payload };
    case GET:
      return { modals: state.modals };
    default:
      return state;
  }
}

export default modal;
