import React, { Component } from 'react'
import { connect } from 'react-redux';
import { RootState } from '../../../reducers'

interface Props {
  homeInfo?: HomeInfoType;
  location?: any;
  history?: any;
  title?: string;
  appInfo?: AppInfoType;
}

interface State {
  copyright: string;
  footerItems: ObjType[];
  modified_date: string;
}

class LayoutFooter extends Component<Props, State> {
  public static defaultProps = {};

  constructor(props: Props) {
    super(props);
    const data = props.homeInfo;
    this.state = {
      modified_date: data?.modified_date || "",
      copyright: data?.footer.filter((v:any) => v.type === 'copyright')[0]?.text as string || "",
      footerItems: data?.footer.filter((v:any) => v.type === 'item') || []
    };
  }

  componentDidUpdate() {
    const data = this.props.homeInfo;
    if (!data || !Array.isArray(data.footer) || !data?.footer[0]?.type || this.state.modified_date === data?.modified_date) return;
    
    this.setState({
      modified_date: data?.modified_date,
      copyright: data?.footer.filter((v:any) => v.type === 'copyright')[0].text as string,
      footerItems: data?.footer.filter((v:any) => v.type === 'item')
    });
  }

  render() {
    return (
      <nav className={`layout-footer footer bg-footer-theme`}>
        <div className="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
          <div className="pt-3">
            <span className="footer-text">{this.state.copyright}</span>
          </div>
          <div>
            {
              this.state.footerItems.map((v: any, i: number) => {
                return <a key={i} href={v.link} target={(!v.link || /^\//.test(v.link)) ? "" : "_blank"} rel="noopener noreferrer" className={`footer-link pt-3${i > 0 ? " ml-4" : ""}`}>{v.text}</a>
              })
            }
          </div>
        </div>
      </nav>
    )
  }
}

export default connect(
  (state: RootState) => ({ 
    homeInfo: state.home.homeInfo,
  }),
  null
)(LayoutFooter)
