import React, { Component } from 'react';
import { apim_logs } from '../apim_logs';

interface Props {
	user: any;
	className?: string;
}

class Avatar extends Component<Props> {
	constructor(props: any) {
		super(props);
	}

	render(): React.ReactNode {
		const user = this.props.user;
		// id base random color
		let randomColor = '',
			idCode = '';
		if (user?.id) {
			try {
				const idAlphabet = ((user.id as string).match(/[a-z0-9]/gi) as string[]).join('').substr(0, 6).padEnd(6, 'a').toLowerCase();
				idCode = idAlphabet.substr(0, 2);
				const baseCode = Math.abs(
					parseInt(
						idAlphabet
							.split('')
							.map((v: string) => parseInt(v) || v.charCodeAt(0) - 95)
							.join('')
					)
				);
				randomColor =
					'#' +
					Math.floor(16777215 * baseCode)
						.toString(16)
						.toString()
						.substr(0, 6);
			} catch (e) {
				apim_logs('user icon error', e);
			}
		}

		const className = this.props.className || '';
		return (
			<>
				{!user?.id ? (
					<img className={'avatar d-block rounded-circle ' + className} src={`${process.env.PUBLIC_URL}/img/common/user.svg`} alt="Login icon" />
				) : user.icon ? (
					<img className={'avatar d-block rounded-circle ' + className} src={user.icon} onError={(e: any) => (e.target.src = `${process.env.PUBLIC_URL}/img/common/user.svg`)} style={{ border: '1px dashed #333' }} alt="User icon" />
				) : (
					<div className={'avatar d-block rounded-circle ' + className}>
						<div className="user-avart-text" style={{ backgroundColor: randomColor + '55', borderColor: randomColor }}>
							<span>{idCode.toUpperCase()}</span>
						</div>
					</div>
				)}
			</>
		);
	}
}

export default Avatar;
