import React from 'react';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import Loader from './shared/Loader';

// Layouts
import LayoutMain from './shared/layouts/LayoutMain';
import LayoutAppsMain from './shared/layouts/LayoutAppsMain';
import LayoutApps from './shared/layouts/LayoutApps';
import LayoutAdmin from './shared/layouts/LayoutAdmin';
import LayoutBlank from './shared/layouts/LayoutBlank';
import LayoutMypage from './shared/layouts/LayoutMypage';
import LayoutForum from './shared/layouts/LayoutForum';

// Lazy load component
const lazy = (cb) => loadable(() => pMinDelay(cb(), 200), { fallback: <Loader /> });

export const DefaultLayout = LayoutMain;
export const defaultRoute = '/';
export const routes = [
	{
		path: '/',
		component: lazy(() => import('./views/Main')),
	},
	// sample
	{
		path: '/sample/sample',
		component: lazy(() => import('./views/sample/Sample')),
		layout: LayoutApps,
	},

	// forum
	{
		path: ['/noti'],
		component: lazy(() => import('./views/Forum/PostList_noti')),
		layout: LayoutForum,
	},
	{
		path: ['/forum'],
		component: lazy(() => import('./views/Forum/PostList_forum')),
		layout: LayoutForum,
	},
	{
		path: ['/forum/edit', '/noti/edit'],
		component: lazy(() => import('./views/Forum/PostEdit')),
		layout: LayoutForum,
	},
	{
		path: ['/forum/view', '/noti/view'],
		component: lazy(() => import('./views/Forum/PostView')),
		layout: LayoutForum,
	},

	// login
	{
		path: '/signin',
		component: lazy(() => import('./views/SignIn')),
		layout: LayoutBlank,
	},
	{
		path: '/signup',
		component: lazy(() => import('./views/SignUp')),
		layout: LayoutBlank,
	},
	{
		path: '/user-info',
		component: lazy(() => import('./views/UserInfo')),
		layout: LayoutMypage,
		title: '회원 정보 수정',
	},
	{
		path: '/password_forgot',
		component: lazy(() => import('./views/PasswordForgot')),
		layout: LayoutBlank,
	},
	{
		path: '/password_reset',
		component: lazy(() => import('./views/PasswordReset')),
		layout: LayoutBlank,
	},

	// readme
	{
		path: '/readme',
		component: lazy(() => import('./views/ReadMe')),
		layout: LayoutAppsMain,
	},
	// document
	{
		path: '/document',
		component: lazy(() => import('./views/Document')),
		layout: LayoutAppsMain,
	},

	// MyApps. : 개별항목으로 application으로 이동
	{
		path: '/my-apps',
		component: lazy(() => import('./views/MyApps')),
		layout: LayoutAppsMain,
	},

	// application
	{
		path: '/application/info',
		component: lazy(() => import('./views/application/Info')),
		layout: LayoutApps,
		title: 'Application 기본 정보',
	},
	{
		path: '/application/api-key',
		component: lazy(() => import('./views/application/ApiKey')),
		layout: LayoutApps,
		title: 'API KEY',
	},
	{
		path: '/application/api-list',
		component: lazy(() => import('./views/application/ApiList')),
		layout: LayoutApps,
		title: 'API 사용',
	},
	{
		path: '/application/api-list/documents',
		component: lazy(() => import('./views/application/APISwagger')),
		layout: LayoutApps,
		title: 'API 문서 및 테스트',
	},
	{
		path: '/application/member-list',
		component: lazy(() => import('./views/application/MemberList')),
		layout: LayoutApps,
		title: '멤버 관리',
	},
	{
		path: '/application/ip-block',
		component: lazy(() => import('./views/application/IpBlock')),
		layout: LayoutApps,
		title: 'IP/Referer Whitelist',
	},
	// 통계
	{
		path: '/application/statistics',
		component: lazy(() => import('./views/application/Statistics')),
		layout: LayoutApps,
		title: 'Application 통계',
	},

	// 관리자
	{
		path: '/administration/home',
		component: lazy(() => import('./views/admin/Home')),
		layout: LayoutAdmin,
		title: 'Home 관리',
	},
	{
		path: '/administration/menu',
		component: lazy(() => import('./views/admin/Menu')),
		layout: LayoutAdmin,
		title: 'Menu 관리',
	},
	{
		path: '/administration/menu/contents',
		component: lazy(() => import('./views/admin/MenuContents')),
		layout: LayoutAppsMain,
		title: 'Menu Contents 수정',
	},
	{
		path: '/administration/domain',
		component: lazy(() => import('./views/admin/Domain')),
		layout: LayoutAdmin,
		title: 'Domain 관리',
	},
	{
		path: '/administration/product',
		component: lazy(() => import('./views/admin/Product')),
		layout: LayoutAdmin,
		title: 'Product 관리',
	},
	{
		path: '/administration/product/detail',
		component: lazy(() => import('./views/admin/ProductDetail')),
		layout: LayoutAdmin,
		title: 'Product 상세',
	},
	// {
	//   path: "/administration/application",
	//   component: lazy(() => import("./views/admin/Application")),
	//   layout: LayoutAdmin,
	//   title: "Application",
	// },
	{
		path: '/administration/api-request',
		component: lazy(() => import('./views/admin/APIRequest')),
		layout: LayoutAdmin,
		title: 'API 사용 요청',
	},
	{
		path: '/administration/statistics',
		component: lazy(() => import('./views/application/Statistics')),
		layout: LayoutAdmin,
		title: 'Application 통계',
	},
	// 약관 관리
	{
		path: '/administration/termsConditons',
		component: lazy(() => import('./views/admin/TermsConditons')),
		layout: LayoutAdmin,
		title: '약관 관리',
	},
	// 세부 약관 정보
	{
		path: '/administration/termsConditons/detail',
		component: lazy(() => import('./views/admin/TermsConditonsDetail')),
		layout: LayoutAdmin,
		title: '약관 상세내용',
	},
	// 약관 그룹관리
	{
		path: '/administration/termsConditons/group',
		component: lazy(() => import('./views/admin/TermsConditonsGroup')),
		layout: LayoutAdmin,
		title: '약관 상세 그룹내용',
	},

	// 약관 승인자 목록 관리
	{
		path: '/administration/termsConditons/agreeUser',
		component: lazy(() => import('./views/admin/TermsAgreeUserList')),
		layout: LayoutAdmin,
		title: '약관 승인자 관리',
	},
	// 약관 승인자 목록 관리
	{
		path: '/administration/setting',
		component: lazy(() => import('./views/admin/Setting')),
		layout: LayoutAdmin,
		title: '설정',
	},
];
