import { ReactNode } from 'react';
// import { v4 as uuidv4 } from 'uuid';

export type ToastType = {
  show: boolean;
  type: 'success' | 'warning' | 'danger' | 'default';
  header?: ReactNode;
  body?: ReactNode;
  time?: number | boolean;
}

const GET = 'toast/GET' as const;
const SET = 'toast/SET' as const;

export const getToast = () => ({
  type: GET
});

export const setToast = (item: ToastType) => ({
  type: SET,
  payload: item
});

type toastAction = ReturnType<typeof getToast> | ReturnType<typeof setToast>;

type toastState = {
  toasts: ToastType;
};

const initialState: toastState = {
  toasts: { show: false, type: 'default' }
};

function toast(state: toastState = initialState, action: toastAction): toastState {
  switch (action.type) {
    case SET:
        // state.toasts.push(action.payload);
        // return { toasts: state.toasts };
        return { toasts: action.payload };
    case GET:
        return { toasts: state.toasts };
    default:
        return state;
  }
}

export default toast;