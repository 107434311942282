import { baseURL } from "../../apis"

const GET_INFO = 'setting/GET_INFO' as const;
const SET_INFO = 'setting/SET_INFO' as const;



export const getSettingInfo = () => ({ type: GET_INFO })
export const setSettingInfo = (settingInfo: SettingInfoType) => ({ type: SET_INFO, payload: settingInfo })

type settingActionType = ReturnType<typeof getSettingInfo> | ReturnType<typeof setSettingInfo>;

type settingState = {
  settingInfo: SettingInfoType;
};

const initialState: settingState = {
  settingInfo: {
    setting: [],
  }
};

function setting(state: settingState = initialState, action: settingActionType): settingState {
  switch (action.type) {
    case SET_INFO:
      return { settingInfo: action.payload };
    case GET_INFO:
      return { settingInfo: state.settingInfo };
    default:
      return state;
  }
}

export default setting;