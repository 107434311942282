import React, { Component } from 'react'

class LayoutBlank extends Component {
 
  render() {
    return (
      <div className="layout-wrapper layout-1 layout-without-sidenav dp">
        {this.props.children}
      </div>
    )
  }
}

export default LayoutBlank
