import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import devApi, {publicApi} from "../../../apis";

interface Props {
	history?: any;
}

interface State {
	menu: any;
}

class Breadcrumbs extends Component<Props, State> {
	public pathArr: string[];
	private lastIndex: number;
	private path = window.location.pathname;

	private middleRouterMap: Record<string, string> = {
		'/administration': '/administration/home',
		'/application': '/my-apps',
	};

	constructor(props: any) {
		super(props);
		this.state = {
			menu: [],
		};
		this.pathArr = this.path.split('/');
		this.lastIndex = this.pathArr.length - 1;
	}


	async componentDidMount() {
		publicApi
			.get(`/menu`)
			.then(({data}) => {
				// console.log("mjmjmjmjmj-menu-data", data)
				// console.log("mj-add-menu-data", data)
				this.setState({
					menu: data,
				});
			})
			.catch((err: any) => {
				console.warn(`[Error.menuList] ${err}`);
			});
	}

	movePath(key: number) {
		let path = '';

		this.pathArr.some((v: string, i: number) => {
			path += v + (i === key ? '' : '/');
			return i === key;
		});

		if (path === this.path) return;
		this.props.history.push(`${this.middleRouterMap[path] || path}${/^\/application/.test(path) ? window.location.search : ''}`);
	}

	pathMap(prevPath: string, path: string): string {
		if (!path) return 'Home';
		// console.log("mjmjmj", path)

		if (prevPath === 'administration') {
			switch (path) {
				case 'home':
					path = 'Home 관리';
					break;
				case 'menu':
					path = 'Menu 관리';
					break;
				case 'domain':
					path = 'Domain';
					break;
				case 'product':
					path = 'Product 관리';
					break;
				case 'api-request':
					path = 'API 사용 요청 관리';
					break;
				case 'termsConditons':
					path = '약관 관리';
					break;
				case 'setting':
					path = '설정';
					break;
			}
		} else if(prevPath === 'termsConditons'){
			switch (path) {
				case 'detail':
					path = '약관 관리 상세';
					break;
				case 'group':
					path = '약관 그룹 관리 상세';
					break;
			}
		} else if (prevPath === 'application') {
			switch (path) {
				case 'info':
					path = '기본 정보';
					break;
				case 'api-key':
					path = 'API KEY';
					break;
				case 'api-list':
					path = 'API 사용';
					break;
				case 'member-list':
					path = '멤버 관리';
					break;
				case 'ip-block':
					path = 'IP/Referer Whitelist';
					break;
			}
		} else if (prevPath === 'forum') {
			// console.log("mjmj-menu-prevPath", this.state.menu)
			switch (path) {
				case 'noti':
					path = '공지사항';
					break;
				case 'view':
					path = '글보기';
					break;
				case 'edit':
					path = '글쓰기';
					break;
			}
		} else {
			console.log("mjmj-menu-path", this.state.menu)
			console.log("mjmjmjmjmjmjmjmj", path)
			switch (path) {
				case 'administration':
					path = 'Admin';
					break;
				case 'readme':
					path = (new URLSearchParams(window.location.search).get('name') as string) || path;
					break;
				case 'application':
					path = 'Application';
					break;
				case 'forum':
					for(let i=0; i< this.state.menu.length; i++){
						if(this.state.menu[i].originText == "Forum"){
							path = this.state.menu[i].text;
							break;
						}else{
							path = '개발자 포럼';
						}
					}
					break;
				case 'my-apps':
					for(let i=0; i< this.state.menu.length; i++){
						if(this.state.menu[i].originText == "Application"){
							path = this.state.menu[i].text;
							break;
						}else{
							path = 'my-apps';
						}
					}
					break;
				case 'noti':
					for(let i=0; i< this.state.menu.length; i++){
						if(this.state.menu[i].originText == "Forum"){
							path = this.state.menu[i].text;
							break;
						}else{
							path = 'noti';
						}
					}
					break;
					// console.log("mjmj-name", new URLSearchParams(window.location.search))
					// path = '개발자 포럼';
          break;
        case 'statistics':
          path = 'Application 통계';
          break;
			}
		}

		return path;
	}

	render() {
		console.log("mjmj-this.pathArr", this.pathArr)
		return (
			<div className={`ui-block${/^\/administration/.test(window.location.pathname) ? ' admin' : ''}`}>
				<div className="cui-example cui-example-vertical-spacing">
					<Breadcrumb>
						<Breadcrumb.Item onClick={() => this.movePath(0)}>Home</Breadcrumb.Item>
						{Array.isArray(this.pathArr) &&
							this.pathArr.map((path: string, i: number, arr: string[]) => {
								return (
									!!path && (
										<Breadcrumb.Item active={i === this.lastIndex} key={i} onClick={() => this.movePath(i)}>
											{this.pathMap(arr[i - 1], path)}
										</Breadcrumb.Item>
									)
								);
							})}
					</Breadcrumb>
				</div>
			</div>
		);
	}
}

export default Breadcrumbs;
