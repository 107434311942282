const GET = 'user/GET' as const;
const SET = 'user/SET' as const;

export const getUser = () => ({
  type: GET
});

export const setUser = (data: UserType) => ({
  type: SET,
  payload: data
});

type userAction =
  | ReturnType<typeof getUser>
  | ReturnType<typeof setUser>;

type userState = {
  data: UserType;
};

const initialState: userState = {
  data: {
    companyHomepage: "",
    companyName: "",
    securityQuestion: "",
    email: "",
    id: "",
    name: "",
    role: []
  }
};

function user(
  state: userState = initialState,
  action: userAction
): userState {
  switch (action.type) {
    case SET:
      return { data: state.data = action.payload };
    case GET:
      return { data: state.data };
    default:
      return state;
  }
}

export default user;