import { combineReducers } from 'redux';
import sample from './actions/sample';
import user from './actions/user';
import toast from './actions/toast';
import modal from './actions/modal';
import apps from './actions/apps';
import home from './actions/home';
import userAPI from './actions/userAPI';
import products from './actions/products';
import terms from './actions/terms';
import setting from './actions/setting';
import commentInfo from './actions/commentInfo';

const rootReducer = combineReducers({
    sample,
    user,
    toast,
    modal,
    apps,
    home,
    userAPI,
    products,
    terms,
    setting,
    commentInfo,
});

// 루트 리듀서
export default rootReducer;

// 루트 리듀서의 반환값를 유추
// 추후 이 타입을 컨테이너 컴포넌트에서 불러와서 사용
export type RootState = ReturnType<typeof rootReducer>;