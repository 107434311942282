import React, {Component, CSSProperties, Fragment, ReactNode, SyntheticEvent} from 'react'
import { Card } from 'react-bootstrap'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import BootstrapTable, {TableChangeHandler} from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

const { SearchBar } = Search;



interface Props {
  data: ObjType[] | null | undefined;
  columns: ObjType[];
  children?: ReactNode;
  selectRow?: {
    mode: 'checkbox' | 'radio';
    classes?: 'table-success' | 'table-info' | 'table-danger' | 'table-warning';
    onSelect?: (row: any, isSelected: boolean, rowIndex: number, e: SyntheticEvent<Element, Event>) => boolean | void;
    onSelectAll?: (isSelect: boolean, rows: any, e: SyntheticEvent<Element, Event>) => void | number[] | undefined;
    nonSelectable?: number[];
  };
  rowStyle?: ((row: any, rowIndex: number) => {}) | {};
  paginationOption?: ObjType;
  search?: boolean;
  keyField?: string;
  serverPaging?: (page: number, sizePerPage: number) =>  Promise<{data: ObjType[] | undefined, totalSize: number}>,
  totalSize? : number;

  onTableChange?: (
      type: string,
      { page, sizePerPage}: {
        page: number;
        sizePerPage: number;
        // sortField: string | undefined;
        // sortOrder: 'asc' | 'desc' | undefined;
      }
  ) => Promise<void>;
  handleSearchChange?: (e: React.ChangeEvent<HTMLInputElement>) => void; // 검색 입력 변경 핸들러
  handleOnSearch?: () => void; // 검색 버튼 클릭 핸들러
  remote? : boolean;
  enableCustomSearch?: boolean; // 커스텀 검색창 활성화 여부
  searchInputRef?: React.RefObject<HTMLInputElement>;
  searchRemain?: string
}

class ArticlesList extends Component<Props> {
  public static defaultProps = {
    search: true,
    enableCustomSearch: false,
  };

  state = {
    data: [],
    columns: [],
    totalSize: 0,
    paginationOption: {},
    searchText: this.props.searchRemain,
    selectRow: this.props.selectRow,
  };
  // async handleSearchChange(e: React.ChangeEvent<HTMLInputElement>){
  //   console.log("글씨 써짐2", e.target.value )
  //   this.setState({
  //     searchText: e.target.value
  //   });
  // };

  handleSearchChange = (e: any) => {
    const searchText = e.target.value;
    this.setState({ searchText });
    this.props.handleSearchChange?.(searchText);
  };
  handleOnSearch = (e: any) => {
    e.stopPropagation();
    this.props.handleOnSearch?.();
  };
  handleEnterPress = (e: any) => {
    if(e.key === 'Enter'){
      // e.preventDefault();
      e.stopPropagation();
      this.props.handleOnSearch?.();
    }
  };

  static getDerivedStateFromProps(props: Props) {
    const isArr = Array.isArray(props.data);
    if (!isArr) console.warn("[ArticlesList.data.error] data is not array : " + props.data)
    return {
      data: isArr ? props.data : [],
      columns: props.columns,
      paginationOption: props.paginationOption,
      selectRow: props.selectRow,
    };
  }

  render() {
    return (
      <ToolkitProvider
        keyField={this.props.keyField || "id"}
        data={this.state.data || []}
        columns={this.state.columns}
        bootstrap4
        search
        >
        {props => (<Fragment>
          {/*/!* Search bar *!/*/}
          {/*{this.props.search && <Card.Body className="pt-4 d-flex justify-content-end px-2 py-2">*/}
          {/*  <div>*/}
          {/*    /!* <Icon path={mdiMagnify} size={0.8} />&nbsp; *!/*/}
          {/*    <SearchBar {...props.searchProps} />*/}
          {/*  </div>*/}
          {/*  <div className="py-2 pl-2">*/}
          {/*    {this.props.children}*/}
          {/*  </div>*/}
          {/*</Card.Body>}*/}

          {/*/!* @ts-ignore *!/*/}

          {this.props.enableCustomSearch ?(
              <Card.Body className="pt-4 d-flex justify-content-end px-2 py-2">
                <div>
                  <input
                      type="text"
                      placeholder="검색..."
                      value={this.state.searchText}
                      onChange={this.handleSearchChange}
                      ref={this.props.searchInputRef}
                      onKeyUp={(e) => this.handleEnterPress(e)}
                  />
                  <button type="button" onClick={(e) => this.handleOnSearch?.(e)}>검색</button>
                </div>
                <div className="py-2 pl-2">
                  {this.props.children}
                </div>
              </Card.Body>
          ) : (
              this.props.search && (
                  <Card.Body className="pt-4 d-flex justify-content-end px-2 py-2">
                    <div>
                      {/* 기존 SearchBar */}
                      <SearchBar {...props.searchProps} />
                    </div>
                    <div className="py-2 pl-2">
                      {this.props.children}
                    </div>
                  </Card.Body>
              ))}

          {/* BootstrapTable */}
          <BootstrapTable
            {...props.baseProps}
            bordered={false}
            hover={true}
            wrapperClasses="table-responsive table-striped react-bs-table-card"
            classes="card-table border-top border-bottom mb-3"
            pagination={paginationFactory(this.state.paginationOption as ObjType)}
            selectRow = {this.state.selectRow}
            noDataIndication={ () => !!this.props.data ? "조회된 데이터가 없습니다." : "데이터 조회중..." }
            onTableChange={this.props.onTableChange}
            remote={this.props.remote}
            rowStyle={this.props.rowStyle}
          />
        </Fragment>)}
      </ToolkitProvider>
    )
  }
}

export default ArticlesList
